import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from '../../utils/Helper';
import HandShakeIcon from '../icons/HandShakeIcon';
import classes from '../layouts/TopAgent.module.css'
import { Cluster } from '../../pages';
import Image from 'next/image';

interface IProps {
    id: number
    name: string
    project: string
    url: string
    clusters: Cluster[]
    cluster: number
    unit: number
    slug: string,
    profileDescription: string,
    onWaClickButton: (unit: any, agent?: boolean, agentWa?: number) => void
    containerClassName?: string
}

const AgentCardAlt = (props: IProps) => {
    const [isMore, setIsMore] = useState(false)
    const [isMobileBp, setIsMobileBp] = useState(true)

    useEffect(() => {
        setIsMobileBp(isMobile())
    }, [])

    return (
        <div
            className={`relative bg-white p-5 rounded-lg border md:mx-3 mx-0 mb-0 border-main transition-all duration-150 ease-in-out ${props.containerClassName || ''}`}
        >
            <div className='flex md:flex-row flex-col md:items-center items-start'>
                <div className='flex flex-row items-center'>
                    <div className='w-16 h-16 rounded-full overflow-hidden'>
                        <Image
                            src={props.url ? props.url : `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/img_avatar.png`}
                            layout="fixed"
                            width={64}
                            height={64}
                            alt="avatar"
                        />
                    </div>
                    <div className='ml-3 text-xs'>
                        <p className={`font-semibold truncate text-base`}>{props.name}</p>
                        <p className="text-gray-ab">{props.project ? props.project : 'Digital Sales'}</p>
                        <a href={`/agent/${props.slug}`} className='text-main font-semibold'>
                            <div>
                                <span className='mr-1'>View Agent Detail</span>
                                <FontAwesomeIcon icon={faLongArrowAltRight} className='' />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="flex flex-row gap-3 md:ml-auto md:mr-0 mx-auto mt-3">
                    <div className={`opacity-100 flex flex-row items-center transition-opacity duration-150 ease-linear`}>
                        <img src='https://ecatalog.sinarmasland.com/assets/homepage/house-agent.png' width={21} height={21} className="m-auto" title='house agent' alt="house agent" />
                        {/* <HomeAgentIcon width={39} height={39} className="m-auto" /> */}
                        <div className='ml-2'>
                            <p className="text-md text-main font-bold">
                                {props.cluster}
                            </p>
                            <p className="text-gray-ab text-xs font-semibold">
                                Clusters
                            </p>
                        </div>
                    </div>
                    <div className={`opacity-100 flex flex-row items-center transition-opacity duration-150 ease-linear`}>
                        <HandShakeIcon width={21} height={21} className="m-auto" />
                        <div className='ml-2'>
                            <p className="text-md text-main font-bold">
                                {props.unit}
                            </p>
                            <p className="text-gray-ab text-xs font-semibold">
                                Unit Types
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <p className={`text-xs `}>
                    {(isMore || props.profileDescription.length < 400) ? props.profileDescription :
                        <>
                            <span>{props.profileDescription.slice(0, 400)} ... </span>
                            <span className='text-main font-semibold underline cursor-pointer' onClick={() => setIsMore(!isMore)}>
                                See More
                            </span>
                        </>
                    }
                </p>
            </div>
            <div className='mt-4'>
                <div className='flex flex-row gap-1'>
                    {
                        props.clusters && props.clusters.length ?
                            props.clusters.map((item, idx) => {
                                if (idx < (isMobileBp ? 2 : 3)) {
                                    return (
                                        (
                                            <div key={item.id} className='flex-1 flex flex-col gap-2'>
                                                <a href={`/project/${item.project_slug}/cluster/${item.slug}`} className={`relative w-full overflow-hidden rounded h-32`}>
                                                    <div className={`inset-0 w-full h-full absolute`}>
                                                        <Image
                                                            src={item.primary_image_thumbnail}
                                                            alt={item.name}
                                                            layout='fill'
                                                            className='object-cover object-center'
                                                        />
                                                    </div>
                                                    <div
                                                        className="absolute inset-0 h-full w-full px-2 py-4 flex flex-col items-start justify-end text-white"
                                                        style={{
                                                            backgroundImage: `linear-gradient(360deg, rgba(1, 1, 1, 0.8) 0%, rgba(1, 1, 1, 0) 60.89%)`,
                                                            zIndex: 1
                                                        }}
                                                    >
                                                        <span className="text-sm font-semibold leading-none mb-1">
                                                            {item.name}
                                                        </span>
                                                        <span className="text-xs font-semibold leading-none">
                                                            {item.location}
                                                        </span>
                                                    </div>
                                                </a>
                                                <div className="flex-1" key={idx}>
                                                    <button onClick={() => props.onWaClickButton(item, true, props.id)} className={`w-full rounded focus:shadow-outline py-2 px-4 lg:px-5 bg-whatsapp text-white hover:bg-whatsapp active:bg-whatsapp text-xs font-semibold focus:outline-none items-center justify-center capitalize `}>
                                                        <div className={`flex items-center justify-center ${classes['va-middle']}`}>
                                                            <img loading="lazy" width="14" height="14" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-Whatsapp.svg`} title='whatsapp' alt="Whatsapp Icon" className="mr-1 my-auto" />
                                                            Whatsapp
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            })
                            : null
                    }
                </div>
            </div>
            <div className='mt-4'>

            </div>
        </div >
    )
}

export default AgentCardAlt