import React, { useState } from 'react'
import LegacyCarousel from '../../../LegacyCarousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import AgentCardAlt from '../../../cards/AgentCardAlt'
import { IClusterAlternatives } from '../../../../utils/Interfaces'
import { TopAgent } from '../../../../pages'
import { useInView } from 'react-intersection-observer'
import dynamic from 'next/dynamic'
const DirectEnquiryModal = dynamic(() => import('../../../modals/DirectEnquiryModal'));

type Props = {
    isMobile: boolean
    dataAgent: TopAgent[]
    lang: any
}

const MeetOurTopAgents = (props: Props) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    })

    const [enquiryData, setEnquiryData] = useState<IClusterAlternatives | null>(null)
    const [isDEMOpen, setIsDEMOpen] = useState(false)
    const [isDEMOpenAgent, setIsDEMOpenAgent] = useState(false)
    const [selectedAgentWa, setSelectedAgentWa] = useState(0)

    const handleWAButtonClick = (unit: IClusterAlternatives, agent?: boolean, agentWa?: number) => {
        setEnquiryData(unit)
        if (agent) {
            setSelectedAgentWa(agentWa ? agentWa : 0)
            setIsDEMOpenAgent(true)
        } else {
            setIsDEMOpen(true)
        }
    }

    const closeDEM = () => {
        setIsDEMOpen(false)
        setIsDEMOpenAgent(false)
        setEnquiryData(null)
    }

    return (
        <>
            {
                inView &&
                <>
                    <DirectEnquiryModal
                        wa_label={enquiryData?.name || ''}
                        lang={props.lang}
                        isOpen={isDEMOpen}
                        typeButton={'whatsapp'}
                        close={closeDEM}
                        isMobile={props.isMobile}
                        clusterId={enquiryData?.id ? Number(enquiryData.id) : 0}
                        cluster={enquiryData?.name}
                        isMultiple
                        extraParams={{
                            is_enquiry_wfh: 1,
                        }}
                        disableWARedirect
                    />
                    <DirectEnquiryModal
                        wa_label={enquiryData?.name || ''}
                        lang={props.lang}
                        isOpen={isDEMOpenAgent}
                        typeButton={'whatsapp'}
                        close={closeDEM}
                        isMobile={props.isMobile}
                        clusterId={enquiryData?.id ? Number(enquiryData.id) : 0}
                        cluster={enquiryData?.name}
                        isMultiple
                        topAgent
                        agent={{
                            id: props.dataAgent[selectedAgentWa] ? props.dataAgent[selectedAgentWa].id : '',
                            name: props.dataAgent[selectedAgentWa] ? props.dataAgent[selectedAgentWa].name : '',
                            email: '',
                            phone: props.dataAgent[selectedAgentWa] ? Number(props.dataAgent[selectedAgentWa].phone) : 0,
                            photo: props.dataAgent[selectedAgentWa] ? props.dataAgent[selectedAgentWa].profile_picture : '',
                            photo_small: props.dataAgent[selectedAgentWa] ? props.dataAgent[selectedAgentWa].profile_picture : '',
                            photo_url: props.dataAgent[selectedAgentWa] ? props.dataAgent[selectedAgentWa].profile_picture : '',
                            photo_small_url: props.dataAgent[selectedAgentWa] ? props.dataAgent[selectedAgentWa].profile_picture : '',
                            is_project_placement: props.dataAgent[selectedAgentWa] ? true : false,
                            is_direct: props.dataAgent[selectedAgentWa] ? 1 : 0,
                            rc: props.dataAgent[selectedAgentWa] ? props.dataAgent[selectedAgentWa].unique_code : '',
                        }}
                    />
                </>
            }
            <div className={`pb-10 lg:pb-16 pt-16 container`}>
                <h2 ref={ref} className="text-left text-lg text-black-48 lg:text-3xl font-bold">Meet Our Top Agents of The Month</h2>
                <span className="text-left text-gray-ab text-xs lg:text-base font-semibold">Connect with them today and you are one step closer to owning your dream property!</span>
                {
                    inView &&
                    <div className="block mt-5 lg:mt-12">
                        <LegacyCarousel
                            show={props.isMobile ? 1 : 2}
                            isRepeatedProps
                            showSliderTranslateOverride={props.isMobile ? 1 : 2}
                            withIndicator
                            leftArrow={prev =>
                                <div className="hidden lg:block absolute left-0 z-1 transform top-1/2 -translate-y-1/2 -translate-x-1/2">
                                    <button onClick={prev} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                        <FontAwesomeIcon icon={faAngleLeft} width="0" className="text-white" size="lg" />
                                    </button>
                                </div>
                            }
                            rightArrow={next =>
                                <div className="hidden lg:block absolute right-0 z-1 transform top-1/2 -translate-y-1/2 translate-x-1/2">
                                    <button onClick={next} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                        <FontAwesomeIcon icon={faAngleRight} width="0" className="text-white" size="lg" />
                                    </button>
                                </div>
                            }
                            // withoutArrow={['xs', 'sm', 'md'].includes(breakpoint)}
                            indicatorSpacing="pt-5"
                            autoSlide={true}
                        >
                            {
                                props.dataAgent.map((item, idx) =>
                                    <div key={idx} className="w-full md:w-1/2 flex-shrink-0 space-y-4 > *">
                                        <AgentCardAlt
                                            clusters={item.clusters}
                                            cluster={item.total_cluster}
                                            unit={item.unit_type}
                                            id={idx}
                                            slug={item.slug}
                                            name={item.name}
                                            url={item.profile_picture}
                                            project={item.project}
                                            onWaClickButton={handleWAButtonClick}
                                            profileDescription={item.profile_description}
                                            containerClassName='h-full'
                                        />
                                    </div>
                                )}
                        </LegacyCarousel>
                    </div>
                }
            </div>
        </>
    )
}

export default React.memo(MeetOurTopAgents) 